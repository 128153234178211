<template lang="html">
  <div class="ui profile segment flat" v-if="profile">
    <div v-if="editable" class="ui top right floating circular label mini">
      <i class="icon fitted delete" @click="deleteStat"></i>
    </div>

    <div class="ui content">
      <div class="content">
        <h3 class="header">
          <span
            class="ui field"
            v-if="editable && isEditing && availableTokens.length > 0"
          >
            <!-- <label>Select an account:</label>
            <select class="ui fluid dropdown" v-model="profile.token_id">
              <option value="">Select an account</option>
              <option v-for="token in availableTokens" :value="token.id">
                {{ token.provider | capitalize }} - {{ token.name }}
              </option>
            </select> -->
          </span>
          <span v-else-if="isEditing && !isLoading">
            Please
            <a href="/tokens" target="_blank">connect your accounts first</a>.
          </span>

          <i
            v-show="!isEditing"
            :class="profileIcon"
            class="icon colored"
            :style="{ color: colors[profile.provider] }"
          ></i>

          <span v-show="!isEditing">
            {{ profileHeading | capitalize }}
          </span>
        </h3>

        <div class="ui field" v-if="profiles.length > 0">
          <label>Select a profile:</label>
          <div class="ui fluid search selection dropdown">
            <input type="hidden" name="profile" v-model.lazy="profile.id" />

            <div class="text">Select a profile</div>
            <i class="dropdown icon"></i>
            <div class="menu">
              <div
                class="item"
                v-for="profile in profiles"
                :data-value="profile.id"
              >
                <i class="ui icon colored" :class="profile.provider"></i>
                {{ profile.name }}
                <div class="hidden">{{ profile.provider }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="ui active dimmer" v-if="loadingStatistics">
          <div class="ui loader"></div>
        </div>

        <div class="ui statistics mini" v-if="viewableStatistics">
          <div
            class="ui statistic left aligned margin-bottom-small"
            v-for="statistic in viewableStatistics"
          >
            <div class="value">
              {{ statistic.value.toLocaleString() }}
            </div>
            <div class="label">
              {{ statistic.label }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue/dist/vue.esm";

// import _ from "lodash";

const $ = window.jQuery;

Vue.filter("capitalize", (value) => {
  if (!value) return "";
  const newValue = value.toString();
  return newValue.charAt(0).toUpperCase() + newValue.slice(1);
});

export default {
  components: {},
  props: {
    editable: false,
    value: {},
    isEditing: false,
    isLoading: false,
    colors: {
      type: Object,
      default() {
        return {
          facebook: "rgba(59, 89, 152, .75)",
          fwitter: "rgba(0, 172, 237, .75)",
          google: "rgba(221, 75, 57, .75)",
          youtube: "rgba(205,32,31, .75)",
          instagram: "rgba(81, 127, 164, .75)",
          pinterest: "rgba(203, 32, 39, .90)",
        };
      },
    },
  },
  data() {
    const $this = this;
    return {
      selectedTokenId: $this.value && this.value.token_id,
      profile: $this.value || {
        id: null,
      },
      availableTokens: [],
      loadingStatistics: false,
      statistics: [],
    };
  },
  watch: {
    // selectedTokenId(newId) {
    //   this.profile = { token_id: newId };
    // },
    "profile.id": {
      handler(val, oldVal) {
        console.log(val);
        const profile = this.profiles.find((p) => p.id === parseInt(val, 10));
        console.log(profile);
        if (profile) {
          this.$set(this, "profile", profile);
        }
      },
    },
    profile: {
      handler() {
        this.loadStatistics();

        this.$set(this.profile, "provider", this.profile.provider);

        this.$emit("input", {
          profile_id: this.profile.id,
        });
      },
      deep: true,
    },
  },
  computed: {
    profiles() {
      return this.availableTokens.map((token) => token.profiles).flat();
    },
    viewableStatistics() {
      return this.statistics.filter((stat) => {
        return !stat.metric_type || !stat.metric_type.includes("demographics");
      });
    },
    token() {
      return this.availableTokens.find((t) => t.id == this.profile.token_id);
    },
    profileIcon() {
      if (this.profile.provider === "google") {
        return "chart line";
      }
      return this.profile.provider;
    },
    profileHeading() {
      let heading = this.profile.name || this.profile.provider;
      if (this.profile.provider === "google") {
        heading = "Website Traffic";
      }
      return `${heading}`;
    },
  },
  methods: {
    initDropowns() {
      $(this.$el)
        .find(".dropdown")
        .each((i, element) => {
          if (!$(element).data("dropdown-loaded")) {
            console.log(element);
            $(element)
              .dropdown({
                clearable: $(element).data("clearable") || false,
                direction: $(element).data("direction") || "auto",
                allowAdditions: $(element).data("additions") || false,
                placeholder: $(element).data("placeholder") || "auto",
                on: $(element).data("on") || "click",
                values: $(element).data("values"),
                fullTextSearch: true,
                forceselection: $(element).data("forceSelection") || false,
              })
              .closest(".dropdown")
              .data("dropdown-loaded", true);
          }
        });
    },
    deleteStat() {
      this.$emit("delete");
    },
    loadAvailableTokens() {
      const $this = this;
      const url = "/tokens.json";
      $this.$emit("update:isLoading", true);
      $.read(url)
        .done((data) => {
          data.forEach((token) => {
            $this.availableTokens.push(token);
          });
          $this.$emit("update:isLoading", false);
        })
        .then(() => {
          $this.initDropowns();
        });
    },

    loadStatistics() {
      if (!this.profile.id) {
        return false;
      }
      const url = `/profiles/${this.profile.id}/statistics.json`;
      this.loadingStatistics = true;
      $.read(url).done((data) => {
        this.statistics = data;
        this.loadingStatistics = false;
      });
      return true;
    },
  },
  mounted() {
    // this.loadTokens();
    if (this.editable) {
      this.loadAvailableTokens();
    }
    this.loadStatistics();
  },
};
</script>

<style lang="scss" scoped>
.profile {
  .top.right.circular.label {
    display: none;
    z-index: 1 !important;
  }
  &:hover {
    .top.right.circular.label {
      display: block;
    }
  }
}

.statistic {
  .value {
    color: rgba(0, 0, 0, 0.87) !important;
    font-size: 24px !important;
    font-weight: bold !important;
    line-height: 32px;
  }
  .label {
    text-transform: none !important;
    color: rgba(0, 0, 0, 0.6) !important;
    font-size: 14px !important;
    line-height: 16px;
    font-weight: normal !important;
  }
}

.content {
  padding-bottom: 2em;
}
</style>
